<div class="mainContainer">
  <!-- Loader Start -->
  <div *ngIf="isLoading == true" class="loader">
    <img src="assets/images/loader.gif" alt="" />
  </div>
  <!-- Loader End -->
  <div class="coverArea">
    <div class="poster">
      <img alt="Image placeholder" [src]="profileDetails.userDetails?.background_image"
        onerror="this.src='assets/images/profileCoverOverlay.png';" />
    </div>
    <button (click)="toggelModal()" type="button" class="btn bckbtn">
      <img src="assets/images/backbutton.png" alt="" />
    </button>
    <div class="top_cntcoverarea">
      <div (click)="toggelModal()" class="profileuser">
        <span class="avtruser">
          <img alt="Image placeholder" [src]="profileDetails.userDetails?.image_url"
            onerror="this.src='assets/images/user.png';" /></span>
        <h3 *ngIf="profileDetails?.userDetails?.first_name.length > 0">
          {{
          profileDetails?.userDetails?.first_name +
          " " +
          profileDetails?.userDetails?.last_name
          }}
        </h3>
      </div>
      <div (click)="toggelModal()" class="cntain">
        <div class="itmRow">
          <div class="itmblck">
            <h3>{{ profileDetails.countDetails?.friends + 0 }}</h3>
            <span>Trust</span>
          </div>
          <div class="itmblck">
            <h3>
              {{
              profileDetails.countDetails?.favItemCount +
              profileDetails.countDetails?.favListCount
              }}
            </h3>
            <span>Faves</span>
          </div>
          <div class="itmblck">
            <h3>{{ profileDetails.countDetails?.listCount + 0 }}</h3>
            <span>lists</span>
          </div>
        </div>
        <button type="button" class="badgebtn btn">
          <img src="assets/images/trustarrow.png" alt="" />
          <span>Trust</span>
        </button>
      </div>
    </div>
    <div (click)="toggelModal()" class="overlayArea">
      <span>About </span>
      <p>
        {{ profileDetails.userDetails?.about }}
      </p>
    </div>
  </div>
  <div class="socialRow">
    <a *ngFor="let n of profileDetails.mediaDetails; let i = index" (click)="goToLink(n.url)" target="_blank"
      class="sclitm"><img alt="Image placeholder" [src]="n.icon"
        onerror="this.src='assets/images/placeHolder.png';" /></a>
  </div>

  <div class="itemsArea">
    <p>Current interests</p>
    <div class="itemrow">
      <div *ngFor="let n of profileDetails.taggedItems; let i = index" class="itemBlock">
        <span (click)="toggelModal()">
          <img alt="Image placeholder" [src]="n.thumbnail_url" onerror="this.src='assets/images/placeHolder.png';" />
        </span>
        <h3>{{ n?.name }}</h3>
        <h5>Attraction</h5>
      </div>
    </div>
  </div>

  <div class="tabarea">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button"
          role="tab" aria-controls="nav-home" aria-selected="true">
          Collections
        </button>
        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button"
          role="tab" aria-controls="nav-profile" aria-selected="false">
          Friends
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div (click)="toggelModal()" class="filterrow">
          <span><img src="assets/images/globeicn.png" alt="" /></span>
          <p>PUBLIC</p>
        </div>
        <div class="listContainer">
          <div (click)="toggelModal()" class="listRow">
            <div class="icnblock">
              <img src="assets/images/listicn01.png" alt="" />
            </div>
            <div class="listcnt">
              <h2>{{ profileDetails?.userDetails?.first_name }}'s lists</h2>
              <span>{{ profileDetails?.countDetails?.favListCount }} lists</span>
            </div>
          </div>
          <div (click)="toggelModal()" class="listRow">
            <div class="icnblock">
              <img src="assets/images/listicn02.png" alt="" />
            </div>
            <div class="listcnt">
              <h2>{{ profileDetails?.userDetails?.first_name }}'s items</h2>
              <span>{{ profileDetails?.countDetails?.favItemCount }} lists</span>
            </div>
          </div>
          <div (click)="toggelModal()" class="listRow">
            <div class="icnblock">
              <img src="assets/images/listicn03.png" alt="" />
            </div>
            <div class="listcnt">
              <h2>Faved</h2>
              <span>{{
                profileDetails?.countDetails?.favItemCount +
                profileDetails?.countDetails?.favListCount
                }}
                items</span>
            </div>
          </div>
          <div (click)="toggelModal()" class="listRow">
            <div class="icnblock">
              <img src="assets/images/listicn06.png" alt="" />
            </div>
            <div class="listcnt">
              <h2>Bucket list</h2>
              <span>{{ profileDetails?.countDetails?.favListCount }} items</span>
            </div>
          </div>
          <div (click)="toggelModal()" class="listRow">
            <div class="icnblock">
              <img src="assets/images/listicn07.png" alt="" />
            </div>
            <div class="listcnt">
              <h2>Disfaved</h2>
              <span>{{ profileDetails?.countDetails?.favItemCount }} items</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
        <div class="listContainer">
          <div *ngFor="let n of friendList; let i = index" class="listRow align-items-center" (click)="toggelModal()">
            <div class="icncrcleblock">
              <img alt="Image placeholder" [src]="n.image_url" onerror="this.src='assets/images/user.png';" />
            </div>
            <div class="listcntsng">
              <h2>{{ n.first_name }} {{ n.last_name }}</h2>
            </div>
            <!-- <button type="button" class="btn btn-outline-primary trustedbtn">
              Trusted
            </button> -->
            <button type="button" class="btn btn-primary trustedbtn">
              Trust
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button #openModal type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
    style="display: none">
    Click modal
  </button>
  <div style="width: 100%; display: block; padding: 1rem">
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 border-0">
            <h3 class="modalhdTop">Want to join 'Faver'. Please download the
              application from respective Stores</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-0">
            <!-- <h2 class="modalhd">Select the button</h2> -->
            <p class="shortmsg">Download the app now</p>
            <div class="btnrow-modal">
              <a (click)="goToLink(playStorelink)" class="btn"><img src="../assets/images/playstore-btn.png"
                  alt="" /></a>
              <a (click)="goToLink(appStorelink)" class="btn"><img src="../assets/images/appstore-btn.png" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>