import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private apiservice: ApiService) { }

  getUserDetails(id): Observable<any> {
    return this.apiservice.get('users/getFriendProfile?id=' + id + '&ownerId=' + '359', '');
  }
  getFriendList(id): Observable<any> {
    return this.apiservice.get('users/getFriendsOfFriend?id=' + id, '');
  }

}
