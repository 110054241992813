import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders
} from '@angular/common/http';

import { environment } from "../../../environments/environment";
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';;

const API_BASE_URL = environment.apiUrl;
const apiKey = environment.apiKey;

export interface Payload { }

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private endPoint: string;
  constructor(
    private http: HttpClient,
  ) { }



  setApiEndpoint(route: string): void {
    this.endPoint = API_BASE_URL + "/" + route;
  }
  getApiEndPoint(): string {
    return this.endPoint;
  }

  getDefaultHeaders(): HttpHeaders {
    let defaultHeaders = new HttpHeaders();
    defaultHeaders = defaultHeaders.set('Content-Type', 'application/json');
    defaultHeaders = defaultHeaders.set('api_key', apiKey);
    return defaultHeaders;
  }


  get(route: string, paramsPayload: Payload): Observable<any> {
    this.setApiEndpoint(route);
    let httpRequestOptions = { headers: this.getDefaultHeaders() };
    return this.http.get(this.getApiEndPoint(), httpRequestOptions).pipe(
      catchError(this.handleError.bind(this))
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error('Backend returned code :', error.status);

      if (error.status === 401 || error.status === 500 || error.status === 0) {
        return throwError(
          'Your ID Token is Expire. Please Login Again.');
      } else {
        console.log(error);
        return throwError(
          'Something went wrong');
      }
    }
  };
}
