import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProfileService } from './services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'faver';
  public profileDetails: any = {};
  public friendList: any = [];
  public isLoading: boolean = false;
  public modalVisible: boolean = false;
  public playStorelink: any = 'https://play.google.com/store/apps/details?id=com.faver.faverapp';
  public appStorelink: any = 'https://apps.apple.com/us/app/faver/id1515531187';
  @ViewChild('openModal') openModal: ElementRef;

  constructor(
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id']) {
        let id = atob(params['id']);
        console.log(id);
        this.loadProfileDetails(id);
        this.loadFriendList(id);
      }
    });
  }

  loadProfileDetails(id) {
    this.isLoading = true;
    this.profileService.getUserDetails(id)
      .subscribe(
        (response) => {
          console.log(response);
          this.isLoading = false;
          if (response.status) {
            this.profileDetails = response.data;
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
  }

  loadFriendList(id) {
    this.profileService.getFriendList(id)
      .subscribe(
        (response) => {
          console.log(response);
          if (response.status) {
            this.friendList = response.data.friends;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  goToLink(url: string) {
    window.open(url);
  }
  toggelModal() {
    this.openModal.nativeElement.click();
    this.modalVisible = !this.modalVisible;
  }

}
